/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ol: "ol",
    li: "li",
    code: "code",
    a: "a",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Setup Action Order"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "keymap escape to caplock ", React.createElement(_components.code, null, "setxkbmap -option caps:swapescape")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/petrstepanov/gnome-macos-remap"
  }, "keys to macos")), "\n"), "\n", React.createElement(_components.h2, null, "Brew Packages"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "git"), "\n", React.createElement(_components.li, null, "v"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "brew install git\nbrew install wget\nbrew install --cask\n")), "\n", React.createElement(_components.h2, null, "Packages"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo apt-get install gnome-tweak-tool -y && gnome-tweak-tool\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
